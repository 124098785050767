<template>
  <div class="tile is-ancestor is-flex-wrap-wrap">
    PAge des Galeries
  </div>
</template>

<script>
export default {
  name: 'Galerie',
};
</script>

<style></style>
