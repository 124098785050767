import { render, staticRenderFns } from "./RGPD-validation-form.vue?vue&type=template&id=e0824fb8&"
import script from "./RGPD-validation-form.vue?vue&type=script&lang=js&"
export * from "./RGPD-validation-form.vue?vue&type=script&lang=js&"
import style0 from "./RGPD-validation-form.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports